
import { Component, Vue } from 'vue-property-decorator'
import AssignmentCard from './assignment-card/index.vue'
import CloudBaseResourceApi, { CloudBaseResource } from '@/api/maintain/cloud-resource/cloud-base-resource'
import { getterUserInfo } from '@/store'

@Component({
  name: 'CloudResource',
  components: {
    AssignmentCard
  }
})
export default class extends Vue {
  /// 任务列表
  assignmentOption = [] as CloudBaseResource[]

  created () {
    this.getAssignmentOption()
  }

  // 获取任务列表
  getAssignmentOption () {
    CloudBaseResourceApi.query({ page: 1, pageSize: 10000, sorts: [], teamId: getterUserInfo().activedTeamId }).then((response) => {
      this.assignmentOption = response.data?.items as CloudBaseResource[]
    })
  }

  activated () {
    this.getAssignmentOption()
  }
}
