
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getterUserInfo } from '@/store'
import ApplicationApi from '@/api/maintain/application'
import AppInstanceApi from '@/api/maintain/app-instance'

@Component({
  name: 'AssignmentCard'
})
export default class extends Vue {
  @Prop({
    type: Number,
    default: 0
  })
  index!: number

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  desc!: string

  @Prop({
    type: Number,
    default: ''
  })
  path!: number

  @Prop({
    type: Number,
    required: true
  })
  cloudId!: number

  isComplete = false

  mounted () {
    ApplicationApi.query({ page: 1, pageSize: 10, sorts: [], cloudResourceId: this.cloudId }).then((listApplicationData) => {
      listApplicationData.data?.items.forEach(applicationData => {
        AppInstanceApi.listAppInstanceByAppId(applicationData.id!).then(listInstanceData => {
          if (listInstanceData.data?.length) this.isComplete = true
        })
      })
    })
  }

  /** 打开页面 */
  openPage (val: number) {
    this.$router.push({
      name: 'cloud-resource-page',
      params: {
        id: val + '',
        tagName: this.title
      }
    })
  }

  /**
   * 当前编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateCloudBaseResource
  }
}
